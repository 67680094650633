@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.0/font/bootstrap-icons.css');
body {
  padding-top: 60px;
  text-size-adjust: 100%;
}

.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('./components/AdobeStock_full_europe.jpeg');
  background-size: cover;
  background-position: 25% 75%;
  height: 93vh;
}

#tsparticles {
  height: 93vh;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
}

#tsparticles canvas {
  height: 93vh !important;
}

#scrollspyfront .btn {
  border-color: white !important;
  border-radius: 0%;
  position: relative;
  z-index: 2;
}

#scrollspyfront .btn:hover {
  color: black !important;
  background-color: white;
}

#scrollspyfront .btn-move-up {
  transition: transform 0.2s ease-in-out;
}

#scrollspyfront .btn-move-up:hover {
  transform: translateY(-5px);
}

/*
@media (min-width: 768px) {
  .left {
    width: 50%;
  }
  .right {
    width: 50%;
  }
}
*/

@media (max-width: 767.98px) {
  .background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('./components/AdobeStock_full_europe.jpeg');
    background-size: cover;
    background-position: center;
    height: 60vh;
  }

  #tsparticles {
    height: 60vh;
  }
  #tsparticles canvas {
    height: 60vh !important;
  }
  h3 {
    font-size: 1.2rem;
  }
  .btn {
    font-size: 0.77rem;
    padding: 0.25rem 0.5rem;
  }
}

/*
#about .container {
  min-height: 400px;
}
*/

/* Default styles for larger screens */

/*
#about .main-image {
  background-image: url('./components/second_small_about_photo.jpg');
  background-size: cover;
  background-position: 25% 75%;
  background-repeat: no-repeat;
  height: 100%;
}

/* Adjustments for smaller screens */
/*
@media (max-width: 1200px) {
  #about {
    height: auto; /* Reset the height on smaller screens 
    align-items: start; /* Align content at the top 
    flex-direction: column;
  }
}
*/

.main-image {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-image: url('./components/second_small_about_photo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 400px;
}

.card-text {
  font-family: 'Roboto', sans-serif;
}

.card .card-title {
  font-family: 'Poppins', sans-serif;
}

@media screen and (min-width: 992px) {
  /*#scrollspyabout {
    height: 93vh;
  }*/

  .card {
    padding-left: 50px;
    margin-right: 50px;
  }

  .main-image {
    min-height: 600px;
    height: 80vh;
  }

  #about-row {
    align-items: center;
  }

  #contact-right {
    margin-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  #contact-left {
    padding-left: 2rem;
  }

  .contact-direct {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 991.5px) {
  .contact-flex {
    flex-direction: column-reverse !important;
  }
  .main-image {
    min-height: 400px;
  }

  .card {
    padding-top: 1rem !important;
  }

  form .btn {
    font-size: 1rem;
  }

  #contact-right,
  #contact-right p {
    margin-bottom: 2.7rem;
  }
}

#about_button a {
  background-color: #000;
  border: 2px solid #000;
  color: #fff;
  cursor: pointer !important;
  display: inline-block;
  font-family: Poppins;
  padding: 13px 48px;
  text-decoration: none;
  transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
}

#about_button a:hover {
  background-color: transparent;
  color: #000;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

#about_button {
  height: auto;
}

#sall {
  display: block !important;
}

form label {
  margin-top: 0.5rem;
}

/*
.filter-option-inner-inner {
  font-weight: bolder !important;
}*/

/* Navbar transition here : */
/* Menu.css */
/* Menu.css */
.nav-link {
  position: relative;
}

/* Define the initial state of the underline */
.nav-link::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #007bff;
  transition: width 0.3s ease;
}

/* Add the animation class to smoothly hide the underline */
.nav-link.animate-underline::before {
  width: 0;
}

.nav-link.active {
  position: relative;
  font-weight: bold;
}

.nav-link.active::before {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust as needed */
  left: 0;
  width: 100%; /* Initially, set the width to 0 (no line) */
  height: 2px; /* Height of the line */
  background-color: black; /* Color of the line */
  transition: all 0.3s ease; /* Add a smooth transition effect for width */
  animation: loading-line 0.5s linear; /* Start the loading line animation */
}

@keyframes loading-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

form .group-info {
  border-bottom: 1px lightgrey solid;
  margin-bottom: 0 !important;
}

li a {
  text-decoration: none;
  color: black;
}

#phoneBadge {
  background-color: green !important;
}

#emailBadge {
  background-color: lightblue !important;
}

.card {
  word-spacing: 0.1rem;
}

#firstCompanyName {
  font-family: 'Poppins';
  font-weight: 200 !important;
}

#secondCompanyName {
  font-family: 'Poppins';
  color: rgb(214, 138, 14);
}

.priceRow {
  align-items: self-end !important;
}

footer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.suggestion-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
  width: 100%; /* Make it full-width */
}

.suggestion-list li {
  border: 1px black solid;
  padding: 8px;
  cursor: pointer;
}

.suggestion-list li.selected {
  background-color: #f2f2f2;
}

.autocomplete-container .dropdown-menu.show {
  width: 100% !important;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 200px !important;
  overflow: auto;
}

.phone-select {
  max-height: 150px !important;
  overflow-y: auto;
}

input#phoneNumber {
  width: 100% !important;
}
